.mobileStartTrainingForFree {
  position: relative;
  width: max-content;
}

.mobileStartTrainingForFreeImage {
  width: 250px;
  position: absolute;
  bottom: -15px;
  left: 0;
}