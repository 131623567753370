@import '../../../../app/styles/globals/variables.scss';

.reviewBlock {
  background-color: $greyBG;
  border-radius: 30px;
  display: grid;
  gap: 10px;

  .avatar {
    border-radius: 50%;
    grid-area: avatar;
  }

  .nameAndStars {
    grid-area: nameAndStars;
  }

  .text {
    grid-area: text;
  }

  &:global(.desktop), &:global(.tablet) {
    padding: 40px 30px;
    grid-template-columns: 70px 1fr;
    grid-template-areas:
      'avatar nameAndStars'
      '. text'
    ;
  }

  &:global(.tablet) {
    min-width: 575px;
    max-width: 575px;
  }

  &:global(.desktop) {
    min-width: 500px;
    max-width: 500px;
  }

  &:global(.mobile) {
    padding: 30px 20px;
    grid-template-columns: 60px 1fr;
    align-items: center;
    grid-template-areas:
      'avatar nameAndStars'
      'text text'
    ;

    .avatar {
      width: 60px;
    }
  }
}