.root {
  margin-top: 80px;
}

.title {
  color: #0E0E06;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 32px;
}