// @media screen and (max-width: 767px) {
//   .authorization {
//     padding: 16px;
//     min-width: auto;
//   }
// }

.main {
  width: 100%;
  background-color: #0E0E06;
  margin-top: 120px;
}

.root{
  margin: 0 auto;
  max-width: 1400px;
  padding: 40px 10px 0px 10px;
}

.iconWrapper {
  padding: 0px 0px 20px 0px;
  border-bottom: 1px solid #5B5B5B;
}

@media screen and (max-width: 900px) {
  .iconWrapper {
   display: flex;
   justify-content: space-between;
  }
}

.textWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
}

.supportWrapper {
  display: flex;
  flex-direction: column;
}

.supportLink {
  color: #00CC39;
}

.supportItem {
  display: flex;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  align-items: center;
  column-gap: 8px;
}

.mailLink {
  color: #fff;
  text-decoration: none;
}

.vk {
  padding-left: 6px;
}


.linksWrapper {
  display: flex;
  flex-direction: column;
}

.linkItem {
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-decoration: none;
}

.title {
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

@media screen and (max-width: 900px) {
  .title {
    color: #9F9FA0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.list {
  padding: 0px;
  margin: 0px;
}

.list li {
  color: #9F9FA0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  list-style-type: none;
}

.leftSide {
  display: flex;
  column-gap: 30px
}

@media screen and (max-width: 1330px) {
  .leftSide {
    flex-direction: column;
    column-gap: 0px;
    row-gap: 20px;
  }
}