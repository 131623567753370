.root {
  background-color: #F2F2F2;
  border-radius: 12px;
  padding: 40px;
  display: flex;
  column-gap: 32px;
}

.title {
  color: #0E0E06;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
}

.subtitle {
  margin-top: 12px;
  color: #5B5B5B;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.img {
  height: 112px;
  width: 112px;
}