
.block {
  margin: 8px 0px;
  background-color: #F2F2F2;
  border-radius: 16px;
  padding: 20px;

}

.btn {
  border: 1px solid #FFAD30;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.img svg  {
  fill: #000;
}

.up {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.question {
  color: #0E0E06;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.answer {
  color: #5B5B5B;
  font-size: 20px;
  line-height: 30px;
  padding-left: 56px;
}