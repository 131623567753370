.allCoursesConteiner {
  margin-top: 70px;
}

.cardSoonMoreConteiner {
  margin-top: 20px;
}

.haveQuestionsConteiner {
  margin-top: 150px;
}