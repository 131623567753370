@import './colors.scss';

/* 1 step - 2px */
$ui-step: calc(1 / 8);

// Gray
$gray-100: #ffffff;
$gray-200: #e5e5e5;
$gray-300: #cccccc;
$gray-400: #b2b2b2;
$gray-500: #999999;
$gray-600: #7f7f7f;
$gray-700: #666666;
$gray-800: #4c4c4c;
$gray-900: #333333;
$gray-1000: #191919;
$gray-1100: #000000;

// Blue
$blue-500: #1976d2;

// Redesing colors
$gray-2: #898989;

// Size
$size-60: $ui-step * 30rem;
$size-58: $ui-step * 29rem;
$size-56: $ui-step * 28rem;
$size-54: $ui-step * 27rem;
$size-52: $ui-step * 26rem;
$size-50: $ui-step * 25rem;
$size-48: $ui-step * 24rem;
$size-46: $ui-step * 23rem;
$size-44: $ui-step * 22rem;
$size-42: $ui-step * 21rem;
$size-40: $ui-step * 20rem;
$size-38: $ui-step * 19rem;
$size-36: $ui-step * 18rem;
$size-34: $ui-step * 17rem;
$size-32: $ui-step * 16rem;
$size-30: $ui-step * 15rem;
$size-28: $ui-step * 14rem;
$size-26: $ui-step * 13rem;
$size-24: $ui-step * 12rem;
$size-22: $ui-step * 11rem;
$size-20: $ui-step * 10rem;
$size-18: $ui-step * 9rem;
$size-16: $ui-step * 8rem;
$size-15: $ui-step * 7.5rem;
$size-14: $ui-step * 7rem;
$size-13: $ui-step * 6.5rem;
$size-12: $ui-step * 6rem;
$size-10: $ui-step * 5rem;
$size-8: $ui-step * 4rem;
$size-6: $ui-step * 3rem;
$size-4: $ui-step * 2rem;
$size-2: $ui-step * 1rem;

// Spacing
$spacing-32: $size-32;
$spacing-30: $size-30;
$spacing-28: $size-28;
$spacing-26: $size-26;
$spacing-24: $size-24;
$spacing-20: $size-20;
$spacing-16: $size-16;
$spacing-14: $size-14;
$spacing-12: $size-12;
$spacing-10: $size-10;
$spacing-8: $size-8;
$spacing-6: $size-6;
$spacing-4: $size-4;
$spacing-2: $size-2;

// Font-size
$font-size-36: $size-36;
$font-size-32: $size-32;
$font-size-30: $size-30;
$font-size-28: $size-28;
$font-size-26: $size-26;
$font-size-24: $size-24;
$font-size-22: $size-22;
$font-size-20: $size-20;
$font-size-18: $size-18;
$font-size-16: $size-16;
$font-size-15: $size-15;
$font-size-14: $size-14;
$font-size-13: $size-13;
$font-size-12: $size-12;
$font-size-10: $size-10;
$font-size-8: $size-8;

// Line-height
$line-height-40: $size-40;
$line-height-38: $size-38;
$line-height-36: $size-36;
$line-height-34: $size-34;
$line-height-32: $size-32;
$line-height-28: $size-28;
$line-height-24: $size-24;
$line-height-22: $size-22;
$line-height-20: $size-20;
$line-height-18: $size-18;
$line-height-16: $size-16;
$line-height-14: $size-14;
$line-height-12: $size-12;

// Letter-spacing
$letter-spacing-2: 0.02rem;
$letter-spacing-1_3: 0.013rem;
$letter-spacing-1: 0.01rem;
$letter-spacing-0_7: 0.007rem;
$letter-spacing-0_5: 0.005rem;
$letter-spacing-0_4: 0.004rem;
$letter-spacing-0: 0rem;

// Font-family
$font-family-primary: '"Inter-Regular"', sans-serif;

// Font-weight
$font-weight-bold: 700;
$font-weight-semi-bold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;

// Box-shadow
$TopHavigationaNavBar: 0px 4px 20.600000381469727px 0px rgba(8, 8, 9, 0.06);
$AppWhiteBackground: 0px 2px 16px 0px rgba(72, 72, 72, 0.12);
$TabsButtovTab: 0px 2px 16px 0px rgba(72, 72, 72, 0.08);
$AppGreyBackground: 0px 14px 20px -4px rgba(81, 81, 91, 0.08);

// Border-radius
$s-border-radius: $size-12;
$m-border-radius: $size-16;
$button-border-radius: $size-30;

// Constansts
$header-height: 78px;

@mixin scrollbar {
  &::-webkit-scrollbar {
    position: absolute;
    width: $size-8;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $size-4;
    border-right: 2px solid $gray-100;
    border-left: 2px solid $gray-100;
    background-color: rgba(0, 0, 0, 0.15);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  &::-webkit-scrollbar-track {
    margin: $spacing-4 0;
    border: none;
    background-color: $gray-100;
  }
}
