@import '../../../../app//styles/globals/variables.scss';

.desktopTextBlock {
  .btn {
    padding-left: 55px;
    padding-right: 55px;
  }
  
  .blackLittleText {
    margin-right: 60px;
  }
  
  .blackText {
    margin-right: 50px;
  }
  
  .buttonText {
    text-align: center;
  }
  
  .buttonWrapper {
    background-color: $white;
    padding: 16px;
    border-radius: 500px;
    margin: -16px -16px -16px 0;
    z-index: 10;
    position: relative;
  }
  
  .svg {
    position: absolute;
    right: 50px;
    height: 16px;
    width: 16px;
  }
  
  .svgFirst {
    bottom: calc(100% - 2px);
    transform: rotate(-180deg);
  }
  
  .svgSecond {
    top: calc(100% - 2px);
    transform: rotate(-90deg);
  }
  
  .text {
    z-index: 20;
  }
}
