.fullScreenModal {
  position: fixed;
  left: 0;
  top: 0;
  height: 100dvh;
  width: 100%;
  background-color: #0D8E74;
  z-index: 200;
  padding-top: 16px;
}

.fullScreenModalHeader, .fullScreenModalContent {
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}

.fullScreenModalContent {
  overflow: auto;
}
