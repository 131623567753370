.root {
  margin-top: 16px;
  background: linear-gradient(61.14deg, #0879F6 -11.17%, #0BD872 50.6%, #FFFC32 107.26%);
  padding: 36px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}

.wrapper {
  display: flex;
  column-gap: 20px;
  align-items: center;
}

.text {
  color: #0E0E06;
}

.title {
  font-size: 23px;
  font-weight: 600;
  line-height: 28px;
  margin: 0px;
}

.time {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  column-gap: 5px;
  margin: 0px;
}

.link {
  text-decoration: none;
  background-color: #0E0E06;
  width: 235px;
  height: 56px;
  border-radius: 30px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  border: none;
  cursor: pointer;
}