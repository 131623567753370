.progressBarWrapper {
  height: 4px;
  background-color: #E4E4E4;
  border-radius: 18px;
  position: relative;
  width: 100%;
}

.progressBar {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  border-radius: 18px;
}

// color

.progressBar.dark {
  background-color: #0E0E06;
}

.progressBar.green {
  background-color: #0D8E74;
}

.progressBar.yellow {
  background-color: #FFAD30;
}
