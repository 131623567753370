.banner {
  min-width: 330px;
  height: 170px;
  background-size: cover;
  display: block;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  padding: 20px;
}

.wrapper {
  height: 100%;
}

.green {
  background-image: url('../../../../shared/assets/images/bannerGreen.png');
}

.pink {
  background-image: url('../../../../shared/assets/images/bannerPink.png');
}

.blue {
  background-image: url('../../../../shared/assets/images/bannerBlue.png');
}

.yellow {
  background-image: url('../../../../shared/assets/images/bannerYellow.png');
}
