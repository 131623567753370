.root {
  display: flex;
  flex-direction: column;
  max-width: 690px;
  position: relative;
}


.img {
  position: absolute;
  top: -150px;
  left: 680px;
}