.titleReviews {
  position: relative;
  width: max-content;
}

.image {
  position: absolute;
  
  &:global(.desktop), &:global(.tablet) {
    bottom: -40px;
    right: 75px;
  }

  &:global(.mobile) {
    bottom: -20px;
    right: -18px;
    width: 100px;
  }
}