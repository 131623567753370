.input {
  height: 56px;
  border-color: #CCCBD3;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  outline: none;
  padding: 16px;
  transition: all .3s ease;
  width: 100%;
}

.inputContainer {
  position: relative;
}

.label {
  position: absolute;
  background-color: #FFF;
  color: #9F9FA0;
  left: 16px;
  pointer-events: none;
  line-height: 24px;
  padding: 0 4px;
  transition: all .3s ease;
}

.input:focus {
  border-color: #34C759;
}

.labelTop {
  transform: translateY(-50%);
  top: 0;
}

.labelCenter {
  top: 16px;
}

.filled {
  border-color: #0E0E06;
  color: #0E0E06;
}

.inputError {
  border-color: #FF6161;
}

.errorText, .labelError {
  color: #FF6161;
}

.errorText, .labelTop {
  font-size: 12px;
}

.errorText {
  padding-left: 16px;
  padding-top: 6px;
}

.rightNode {
  position: absolute;
  top: 16px;
  right: 18px;
}

.readOnly {
  cursor: default;
}
