@import '../../../../app/styles/globals/colors.scss';

.tabletBlockWithInformationAboutPlatform {
  display: grid;
  grid-template-areas:
  'first second second second'
  'third third fourth fourth'
  ;
  gap: 20px;
}

.text {
  z-index: 1;
}

.first, .second, .third, .fourth {
  position: relative;
  border-radius: 30px;
  height: 180px;
  display: flex;
  align-items: center;
  padding-left: 30px;

  img {
    position: absolute;
  }
}

.first {
  grid-area: first;
  background-color: $blueSoft;

  img {
    top: -25px;
    right: 0;
  }
}

.second {
  grid-area: second;
  background-color: $purpleSoft;
  
  img {
    top: -40px;
    right: 0;
  }
}

.third {
  grid-area: third;
  background-color: $orange;
  
  img {
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
  }
}

.fourth {
  grid-area: fourth;
  background-color: $black;
  
  img {
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
}
