.smsCodeForm {
  background-color: #0D8E74;
  border-radius: 12px;
}

.sendAgain {
  padding: 4px 8px;
  background-color: #078168;
  border-radius: 8px;
}

.codeInput {
  width: 44px;
}

.error {
  background-color: #F2F2F2;
  margin-left: -20px;
  margin-right: -20px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
}
