
.upperBlock {
  background: linear-gradient(52.42deg, #1E5CA0 -8.52%, #02AD58 71.37%, #FAF605 144.65%);
  width: 100%;
}

.textWrapper {
  max-width: 1440px;
  padding: 32px 0px 40px 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
}

.title {
  color: #fff;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
}

.subtitle {
  margin-top: 24px;
  color: #fff;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
}