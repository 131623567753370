.reviewsPageIntroConteiner {
  margin-top: 74px;
}

.reviewsBlocksConteiner {
  margin-top: 95px;
}

.titleContainer {
  margin-top: 125px;
}

.commentsConteiner {
  margin-top: 40px;
}

.haveQuestionsConteiner {
  margin-top: 100px;
}
