@import '../../../../app/styles/globals/variables.scss';

.cardSoonMore {
  & {
    position: relative;
    background-color: $neonGreen;
    border-radius: 30px;
    padding: 45px 30px;
  }

  .text {
    z-index: 10;
  }

  .image {
    position: absolute;
    bottom: 0;
    right: 0;

    @media screen and (max-width: 650px) {
      width: 200px;
      right: -50px;
    }
  }
}
