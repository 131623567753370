.img {
  width: 100%;
  max-width: 128px;
}

.successPaymentPage {
  margin-top: 24px;
  margin-left: 16px;
  margin-right: 16px;
}

.btn {
  width: 300px;
}
