.root {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  border-radius: 24px;
  background-color: #fff;
  padding: 20px;
}

.title {
  color: #0e0e06;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
}