.root {
  border-bottom: 1px solid #C3C3C3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}

.root:last-child {
  border-bottom: none;
}

.right {
  display: flex;
  align-items: center;
  column-gap: 8px;
  
}

.name {
  color: #5B5B5B;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.size {
  color: #9F9FA0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
