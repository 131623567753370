.root {
  margin-top: 70px;
}

.wrapper {
  display: flex;
  column-gap: 20px;
  margin-top: 40px;
}

.bottomBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 70px;
}

.navBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #fff;
  width: 190px;
  height: 56px;
  border-radius: 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  background: #FFAD30;
}

.info {
  padding: 32px;
  border-radius: 24px;
  background-color: #FFFFFF;
  margin-top: 40px;
}
