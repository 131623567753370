.popUp {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #FFFFFF;
  border-radius: 16px;
  width: 100%;
  max-width: 524px;
  z-index: 300;
  max-height: 90dvh;
}

.popUpHeader {
  padding-left: 32px;
  padding-top: 32px;
  padding-right: 32px;
}

.popUpContent {
  overflow: auto;
  width: 100%;
  padding-left: 32px;
  padding-bottom: 32px;
  padding-top: 32px;
  padding-right: 32px;
}

.cross {
  color: #9F9FA0;
}
