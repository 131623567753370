.desktopHaveQuestions {
  height: 500px;
}

.textHaveQuestions {
  position: absolute;
  left: 60px;
  top: 210px;
}

.visualElementsHaveQuestions {
  position: absolute;
  bottom: 0;
  right: 140px;
}
