.button {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  display: block;
  text-decoration: none;
  cursor: pointer;
}

.nonInteractive {
  pointer-events: none;
}

.disabled {
  cursor: default;
}

.stretch {
  width: 100%;
}
