.bannersBg {
  background-image: url('../../../shared/assets/images/bgBanners.jpg');
  margin-top: 70px;
}

.bannersWrapper {
  overflow: auto;
  padding-top: 32px;
  padding-bottom: 32px;
}
