@import '../../../../app/styles/globals/variables.scss';

.sliderBlock {
  & {
    border-radius: 30px;
  }

  .dotsWrapper {
    position: absolute;
  }

  .dot {
    background-color: $white;
    border-radius: 50%;
  }

  .dotActive {
    background-color: $black;
  }

  .sliderBlockInside {
    height: 100%;
    position: relative;
  }

  &:global(.desktop) {
    min-width: 500px;
    max-width: 500px;
    height: 700px;

    .sliderBlockInside {
      margin-right: 30px;
    }

    .dotsWrapper {
      left: 40px;
      top: 40px;
    }

    .dot {
      height: 15px;
      width: 15px;
    }
  }

  &:global(.tablet) {
    min-width: 276px;
    max-width: 276px;
    height: 427px;

    .sliderBlockInside {
      margin-right: 15px;
    }

    .dotsWrapper {
      left: 20px;
      top: 20px;
    }

    .dot {
      height: 15px;
      width: 15px;
    }
  }

  &:global(.mobile) {
    min-width: 328px;
    max-width: 328px;
    height: 442px;

    .sliderBlockInside {
      margin-right: 0px;
    }

    .dotsWrapper {
      right: 25px;
      top: 65px;
    }

    .dot {
      height: 10px;
      width: 10px;
    }
  }
}

.sliderBlockInside {
  margin-right: 30px;
  height: 100%;
  position: relative;
}