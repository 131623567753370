.line {
  background-color: #CCCBD3;
  height: 1px;
  width: 100%;
}

.form {
  background-color: #FFFFFF;
  padding: 32px;
  border-radius: 12px;
  max-width: 600px;

  @media screen and (max-width: 767px) {
    & {
      padding: 12px;
    }
  }
}

.product {
  background-color: rgba(52, 199, 89, 0.08);
  border-radius: 8px;
  padding: 8px;
}

.number {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #FFFFFF;
}

.productPrice {
  background-color: #FFFFFF;
  padding: 4px 8px;
  border-radius: 15px;
}

.cross {
  color: #9F9FA0;
}

.iframe {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-width: 0;
  width: 100%;
  height: 100%;
}

.link {
  text-decoration: none;
  color: #0D8E74;
}
