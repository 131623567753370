.desktopImg {
  position: absolute;
  width: 800px;
  bottom: 0;
  left: 100px;
}

.tabletImg {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 400px;
}

.mobileFirstImage {
  bottom: 0;
  width: 300px;
}

.mobileSecondImage {
  position: absolute;
  top: 75px;
  right: 0;
  width: 115px;
}
