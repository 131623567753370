.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #F2F2F2;
  margin: 14px 0px;
  padding: 14px 8px;
}

.isCurrent {
  background-color: #0D8E74;
  border-radius: 8px;
}

.root :last-child {
  border-bottom: none
}

.text__wrapper {
  display: flex;
  column-gap: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.lesson {
  color: #9F9FA0;
}

.lesson__name {
  color: #0E0E06;
}

.time {
  color: #9F9FA0;
}

.icon {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center
}

.link {
  text-decoration: none;
}

.lessonTextName {
  color: #FFFFFF;
}

.lessonText, .lessonTextTime {
  color: #E4E4E4;
}