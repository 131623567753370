@import '../../../../app/styles/globals/colors.scss';

.courseCardWrapper {
  & {
    position: relative;
    z-index: 10;
  }

  .speakerPhoto, .leftImage, .rightImage, .figureBackground {
    transition: all .3s ease;
  }

  .speakerPhoto.hide,
  .figureBackground.hide,
  .rightImage.hide,
  .leftImage.hide {
    opacity: 0;
  }

  .speakerPhoto {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .leftImage, .rightImage, .speakerPhoto, .figureBackground {
      position: absolute;
      pointer-events: none;
      z-index: 20;
  }

  .leftImage, .rightImage {
    // background-color: red;
  }

  .leftImage {
    width: 155px;
    height: 155px;
    top: -106px;
    left: -38px;
  }

  .rightImage {
    width: 180px;
    height: 180px;
    top: -100px;
    right: -40px;
  }

  .figureBackground {
    z-index: -1;
    bottom: calc(100% - 130px);
    transform: translateX(-50%);
    left: 50%;
  }
}

.courseCard {
  & {
    background-color: $greyBG;
    padding: 50px 25px;
    border-radius: 30px;
    height: 575px;
    transition: all .3s ease;
  }

  &:hover {
    background-color: $black;
  }

  .authorPhoto {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    object-fit: cover;
  }
}
