@import '../../../../app/styles/globals/variables.scss';

.buttonIconUI {
  border-radius: $m-border-radius;
  height: $size-48;
  width: $size-48;
  transition: background-color 0.3s ease;
  border: 2px solid #00CC39;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.buttonIconUI:hover {
  background-color: var(--inverted-bg-color-hover);
}

.buttonIconUI svg {
  height: $size-24;
  width: $size-24;
  fill: var(--inverted-primary-color);
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.button

.round {
  border-radius: 50%;
}
