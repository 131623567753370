.tabletHaveQuestions {
  height: 650px;
}

.textHaveQuestions {
  position: absolute;
  left: 24px;
  top: 40px;
}

.visualElementsHaveQuestions {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
}
