.certificate {
  width: 600px;
  height: 842px;
  position: relative;
}

.bg {
  position: absolute;
  object-fit: cover;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.groupOfPeopleImg {
  z-index: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 28px;
}

.mainBlockWrapper {
  position: relative;
  top: 195px;
  margin: 0 auto;
  width: 560px;
  background-color: #FFFFFF;
  padding: 8px;
  z-index: 2;
  border-radius: 16px;
}

.mainBlock {
  border-color: rgba(0, 204, 57, 0.08);
  border-width: 4px;
  border-style: solid;
  border-radius: 16px;
  padding: 32px;
}

.mt60 {
  margin-top: 60px;
}

.mt20 {
  margin-top: 20px;
}

.mt80 {
  margin-top: 80px;
}
