@import '../../../app/styles/globals/variables.scss';

@mixin getNewTypography($fontWeight, $fontSize, $lineHeight) {
  font-weight: $fontWeight;
  font-size: $fontSize;
  line-height: $lineHeight;
}

.Text {
  @include getNewTypography(400, $font-size-16, $line-height-20);
}

.Bt {
  @include getNewTypography(600, $font-size-16, $line-height-20);
}

.H2 {
  @include getNewTypography(700, 80px, 85px);
  font-family: 'AkzidenzGroteskPro-Regular';
}

.H3 {
  @include getNewTypography(500, 50px, 55px);
  font-family: 'AkzidenzGroteskPro-Regular';
}

.H4 {
  @include getNewTypography(500, 30px, 40px);
  font-family: 'AkzidenzGroteskPro-Regular';
}

.H5 {
  @include getNewTypography(500, 28px, 35px);
  font-family: 'AkzidenzGroteskPro-Regular';
}

.H6 {
  @include getNewTypography(500, 24px, 30px);
  font-family: 'AkzidenzGroteskPro-Regular';
}

.H7 {
  @include getNewTypography(500, 20px, 25px);
}

.tag {
  @include getNewTypography(500, 13px, 15px);
  font-family: 'AkzidenzGroteskPro-Regular';
}

.question {
  @include getNewTypography(600, 24px, 30px);
}

.Title_2_Regular {
  @include getNewTypography(400, 28px, 34px);
  font-family: 'AkzidenzGroteskPro-Regular';
}

.Chapter_MediumExtended {
  @include getNewTypography(500, 48px, 48px);
  font-family: 'AkzidenzGroteskPro-Regular';
}

.Title_3_Regular {
  @include getNewTypography(400, 24px, 34px);
  font-family: 'AkzidenzGroteskPro-Regular';
}

.Headline_LightExtended {
  @include getNewTypography(300, 20px, 26px);
  font-family: 'AkzidenzGroteskPro-Regular';
}

.Text_1_BoldExtended {
  @include getNewTypography(700, 16px, 20px);
  font-family: 'AkzidenzGroteskPro-Regular';
}
