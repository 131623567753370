.newPopUp {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #FFFFFF;
  border-radius: 16px;
  width: 100%;
  max-width: 524px;
  z-index: 500;
  max-height: 90dvh;
}

.newPopUpHeader {
  padding-left: 32px;
  padding-top: 32px;
  padding-right: 32px;
}

.newPopUpContent {
  overflow: auto;
  width: 100%;
  padding-left: 32px;
  padding-bottom: 32px;
  padding-top: 32px;
  padding-right: 32px;
}

.btn {
  position: absolute;
  background-color: #5B5B5B !important;
  right: -50px;
  border-radius: 50%;
  
  img {
    padding: 4px;
  }
}

.cross {
  color: #9F9FA0;
}
