.paymentMethod {
  border-radius: 8px;
  border-width: 1px;
  border-color: #CCCBD3;
  border-style: solid;
  padding: 8px 12px;
  min-width: 100px;
}

.active {
  border-color: #34C759;
}