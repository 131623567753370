.changePassword {
  background-color: #FFFFFF;
  padding: 48px;
  border-radius: 24px;
}

.mobileHide {
  @media screen and (max-width: 767px) {
    display: none;
  }
}
