.textWhyChooseUs {
  &.desktop {
    position: absolute;
    left: 910px;
    right: 80px;
    top: 45px;
  }
  
  &.tablet {
    margin-top: 50px;
    margin-left: 30px;
    padding-bottom: 110px;
    width: 468px;
  }

  &.mobile {
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
  }
}