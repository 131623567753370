@import '../../../app/styles/globals/colors.scss';

.newChip {
  & {
    padding: 10px;
    border-radius: 50px;
    border-color: $black;
    transition: all .3s ease;
  }

  &.filled, &.outline {
    border-style: solid;
    border-width: 1px;
  }

  &.filled.white {
    background-color: $white;
  }

  &.filled.black {
    background-color: $black;
  }

  &.outline.white {
    border-color: $white;
  }

  &.outline.black {
    border-color: $black;
  }
}
