@import '../../../../app/styles/globals/colors.scss';

$gap: 10px;

.buttonHaveQuestions {
  & {
    position: absolute;
    transform: translateY(-100%);
    top: calc(100% + #{$gap});
    left: 50px;
    z-index: 50;
  }

  .buttonWrapper {
    position: relative;
    padding: $gap;
    // background-color: red;
    background-color: $white;
    border-radius: 500px;
  }

  .svg {
    width: 10px;
    position: absolute;
    z-index: 30;
  }

  .svgFirst {
    bottom: 10px;
    left: 0;
    transform: rotate(90deg);
  }

  .svgSecond {
    bottom: 10px;
    right: 0;
    transform: rotate(180deg);
  }
}
