.checkbox {
  border-color: #CCCBD3;
  border-radius: 2px;
  border-width: 2px;
}

.checkbox:checked {
  accent-color: #0D8E74;
}

.error {
  -webkit-box-shadow: 0px 0px 0px 1px rgba(255,0,0,1);
  -moz-box-shadow: 0px 0px 0px 1px rgba(255,0,0,1);
  box-shadow: 0px 0px 0px 1px rgba(255,0,0,1);
}