.blocks {
  position: relative;
}

.block {
  padding: 50px 24px;
  border-radius: 30px;
  background-color: #F8F8F8;
}

.star {
  position: absolute;
  left: 0;
  top: -43px;
}

.percent {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: -40px;
}
