.slide {
  & {
    border-radius: 30px;
    height: 100%;
  }

  .card {
    border-radius: 65px;
    background-color: #F04B17;
    width: max-content;
    position: absolute;
    transform: rotate(4deg);
    padding: 10px 20px;
  }

  .figure {
    position: absolute;
  }

  &:global(.desktop) {
    & {
      padding: 85px 40px;
    }

    .image {
      border-radius: 30px;
      position: absolute;
      right: -30px;
      bottom: 30px;
      width: 315px;
    }

    .card {
      bottom: 50px;
      left: 75px;
    }

    .figure {
      left: 75px;
      bottom: 150px;
      height: 135px;
      width: 135px;
    }
  }

  &:global(.tablet), &:global(.mobile) {
    .card {
      bottom: -20px;
      left: 15px;
    }

    .figure {
      height: 90px;
      width: 90px;
      bottom: 175px;
      left: 25px;
    }
  }

  &:global(.tablet) {
    & {
      padding: 60px 5px 0px 10px;
    }

    .image {
      border-radius: 30px;
      position: absolute;
      right: -15px;
      bottom: -15px;
      width: 190px;
    }
  }

  &:global(.mobile) {
    & {
      padding: 65px 50px 0px 25px;
    }

    .image {
      border-radius: 30px;
      position: absolute;
      right: 25px;
      bottom: -30px;
      width: 190px;
    }
  }
}