@import '../../../../app/styles/globals/variables.scss';

.visualElementsHaveQuestionsWrapper {
  & {
    position: relative;
    height: 100%;
  }

  img {
    width: 700px;
    z-index: 2;
    position: relative;

    @media screen and (max-width: 1499px) {
      top: 185px;
    }

    @media screen and (max-width: 899px) {
      width: 300px;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%) translateY(-100%);
    }
  }

  .firstCard, .secondCard, .thirdCard {
    border-radius: 100px;
    padding: 20px 30px;
    position: absolute;

    @media screen and (max-width: 899px) {
      padding: 5px 10px;
    }
  }

  .firstCard {
    background-color: $purpleSoft;
    transform: rotate(-4deg) translateX(-50%);
    top: 0;
    left: 80px;
    z-index: 3;

    @media screen and (max-width: 1499px) {
      top: 50%;
      left: 70px;
    }

    @media screen and (max-width: 899px) {
      top: 180px;
      left: 150px;
    }
  }

  .secondCard {
    background-color: #D1E54D;
    transform: rotate(-4deg) translateX(-50%) translateY(-50%);
    top: 215px;
    right: -315px;
    z-index: 1;

    @media screen and (max-width: 1499px) {
      top: 60px;
      right: -150px;
    }

    @media screen and (max-width: 899px) {
      top: 280px;
      right: -100px;
    }
  }

  .thirdCard {
    background-color: $orange;
    transform: rotate(4deg) translateX(-50%) translateY(-50%);
    bottom: -25px;
    left: 175px;
    z-index: 3;

    @media screen and (max-width: 899px) {
      bottom: 180px;
      left: 110px;
    }
  }

  .text {
    @media screen and (max-width: 899px) {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }
  }
}