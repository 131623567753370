$IconeAccent: #0D8E74;

.iconeAccent {
  color: $IconeAccent;
}

$LigthTextTextTitle: #0E0E06;

.ligthTextTextTitle {
  color: $LigthTextTextTitle;
}

$TextSecondary: #5B5B5B;

.textSecondary {
  color: $TextSecondary;
}

$ButtonAccent: #00CC39;

.buttonAccent {
  color: $TextSecondary;
}

$ButtenNegative: #FF6161;

.buttenNegative {
  color: $TextSecondary;
}

$LightModal: rgba(243, 243, 243, 1);

.lightModal {
  color: $LightModal;
}

$TextAccent: rgba(0, 204, 57, 1);

.textAccent {
  color: $TextAccent;
}

$TextNegative: rgba(255, 97, 97, 1);

.textNegative {
  color: $TextNegative;
}

$ButtonSecondary: rgba(242, 242, 242, 1);

.buttonSecondary {
  color: $ButtonSecondary;
}

$TextWhiteFixed: #FFFFFF;

.textWhiteFixed {
  color: $ButtonSecondary;
}

// Redesing

$white: #FFFFFF;

.white {
  color: $white;
}

$black: #202020;

.black {
  color: $black;
}

$greenMain: #74B500;

.greenMain {
  color: $greenMain;
}

$grey1: #464646;

.grey1 {
  color: $grey1;
}

$grey2: #898989;

.grey2 {
  color: $grey2;
}

$greyBG: #F8F8F8;

.greyBG {
  color: $greyBG;
}

$neonGreen: #DEFF5B;

.neonGreen {
  color: $neonGreen;
}

$blueSoft: #A0D7FF;

.blueSoft {
  color: $blueSoft;
}

$purpleSoft: #BABEFC;

.purpleSoft {
  color: $purpleSoft;
}

$orange: #FFC852;

.orange {
  color: $orange;
}

$blue: #0053B5;

.blue {
  color: $blue;
}

$blueSoft: #A0D7FF;

.blueSoft {
  color: $blueSoft;
}

$blue: #0053B5;

.blue {
  color: $blue;
}
