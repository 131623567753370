.flex {
  display: flex;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyCenter {
  justify-content: center;
}

.justifyEnd {
  justify-content: flex-end;
}

.justifyBetween {
  justify-content: space-between;
}

.justifyAround {
  justify-content: space-around;
}

.justifyStretch {
  justify-content: stretch;
}

.alignStretch {
  align-items: stretch;
}

.alignStart {
  align-items: flex-start;
}

.alignCenter {
  align-items: center;
}

.alignEnd {
  align-items: flex-end;
}

.directionRow {
  flex-direction: row;
}

.directionRowReverse {
  flex-direction: row-reverse;
}

.directionColumnReverse {
  flex-direction: column-reverse;
}

.directionColumn {
  flex-direction: column;
}

.gap4 {
  gap: 4px;
}

.gap5 {
  gap: 5px;
}

.gap8 {
  gap: 8px;
}

.gap10 {
  gap: 10px;
}

.gap12 {
  gap: 12px;
}

.gap15 {
  gap: 15px;
}

.gap16 {
  gap: 16px;
}

.gap20 {
  gap: 20px;
}

.gap24 {
  gap: 24px;
}

.gap25 {
  gap: 25px;
}

.gap30 {
  gap: 30px;
}

.gap32 {
  gap: 32px;
}

.gap40 {
  gap: 40px;
}

.gap50 {
  gap: 50px;
}

.gap56 {
  gap: 56px;
}

.gap70 {
  gap: 56px;
}

.max {
  width: 100%;
}
