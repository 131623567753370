@import '../../../../app/styles/globals/colors.scss';

.desktopBlockWithInformationAboutPlatform {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr 0.5fr;
  gap: 20px;
}

.text {
  z-index: 1;
}

.first, .second, .third, .fourth {
  position: relative;
  border-radius: 30px;
  height: 180px;
  display: flex;
  align-items: center;
  padding-left: 30px;

  img {
    position: absolute;
  }
}

.first {
 background-color: $blueSoft;

  img {
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
  }
}

.second {
  background-color: $purpleSoft;
  
  img {
    top: -40px;
    right: 0;
  }
}

.third {
  background-color: $orange;
  
  img {
    bottom: 0;
    right: 0;
  }
}

.fourth {
  background-color: $black;
  
  img {
    top: 20px;
    right: -100px;
  }
}
