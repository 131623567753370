.authorization {
  padding: 48px;
  background-color: #FFFFFF;
  box-shadow: 0 30px 60px 0 rgba(86, 99, 93, 0.09);
  max-width: 500px;
  border-radius: 24px;
  min-width: 500px;
}

@media screen and (max-width: 767px) {
  .authorization {
    padding: 16px;
    min-width: auto;
  }
}
