.root {
  width: 130px;
  height: 28px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  background-color: #FEE92D;
}

.text {
  color: #0E0E06;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
}