.description {
  margin-top: 20px;
  color: #5B5B5B;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  text-align: justify;
}

.iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.videoBlock {
  position: relative;
  margin-bottom: 32px;
  border-radius: 20px;
}