.titleWhyChooseUsWrapper {
  &.desktop {
    position: absolute;
    left: 64px;
    top: 384px;
  }

  &.tablet {
    padding-left: 38px;
    padding-top: 64px;
  }

  &.mobile {
    padding-left: 30px;
    padding-top: 20px;
  }

  .titleWhyChooseUs {
    position: relative;
  }

  .titleWhyChooseUsImg {
    position: absolute;
    left: -38px;
    bottom: -40px;
  }

  .titleWhyChooseUsImgMobile {
    width: 85px;
    left: -8px;
    bottom: -15px;
  }
}
