@import '../../../../app//styles/globals/variables.scss';

.mobileTextBlock {
  .btn {
    padding-left: 55px;
    padding-right: 55px;
  }
  
  .blackLittleText {
  }
  
  .blackText {
  }
  
  .buttonText {
    text-align: center;
  }
  
  .buttonWrapper {
    background-color: $white;
    padding: 10px;
    border-radius: 500px;
    margin: 0 15px -40px 15px;
    z-index: 10;
    position: relative;
    margin-top: 20px;
  }
  
  .svg {
    position: absolute;
    height: 16px;
    width: 16px;
    top: calc(100% - 37px);
  }
  
  .svgFirst {
    left: -10px;
  }
  
  .svgSecond {
    transform: rotate(-90deg);
    right: -10px;
  }
  
  .text {
    z-index: 20;
  }
}
