.grid {
  display: grid;
}

.maxHorizontal {
  width: 100%;
}

.maxVertical {
  height: 100%;
}
