@import '../styles/lib/normalize.scss';
@import './globals/colors.scss';
@import url('../styles/lib/fonts.scss');

body {
  font-family: 'Inter-Regular';
  // background-color: #FCFAFA;
  background-color: #FFFFFF;
}

.app {
  background: var(--bg-color);
  min-height: 100vh;
  color: var(--primary-color);
}

.app.duringChangeTheme {
  transition-property: color, background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}

* {
  box-sizing: border-box;
}

img,
svg {
  display: block;
}

.resetButtonTagStyles {
  background: none repeat scroll 0 0 transparent;
  border: medium none;
  border-spacing: 0;
  color: #26589F;
  font-family: 'PT Sans Narrow',sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.42rem;
  list-style: none outside none;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
}

.resetATagStyles {
  color: inherit;
  text-decoration: inherit;
}

button {
  cursor: pointer;
}

// TODO:
#react-refresh-overlay {
  display: none;
}

.textDecorationNone {
  text-decoration: none;
}

a {
  text-decoration: none;
}
