.upWrapper {
  display: flex;
  column-gap: 20px;
}
.card {
  position: relative;
  padding-bottom: 70px;
}


button.rating {
  position: absolute;
  left: 24px;
  bottom: 24px;
  height: 28px;
  background-color: #FAF9F9;
  border-radius: 15px;
  padding: 4px 12px;
}

.imgWrapper {
  width: 150px;
  height: 150px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex: 0 0 auto;
}

.img {
  width: 100%;
  height: 100%;
}
.link {
  text-decoration: none;
}

.title {
  color: #0E0E06;
  font-weight: 600;
  font-size: 30px;
  line-height: 32px;
}

.descr {
  margin-top: 12px;
  color: #5B5B5B;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}