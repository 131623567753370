.mainPage {
  & {
    overflow-x: hidden;
  }

  .mainPageContant {
    margin-top: 32px;
    margin-bottom: 120px;
  }
  
  .mainPageIntroBlock {
    margin-top: 70px;
  }
  
  .mainPageCourses {
    margin-top: 185px;
  }
  
  .mainPageWhyChooseUs {
    margin-top: 150px;
  }
  
  .mainPageStartTrainingForFree {
    margin-top: 150px;
  }

  .mainPageBlockWithInformationAboutPlatform {
    margin-top: 80px;
  }

  .mainPageFreeCoursesShort {
    margin-top: 20px;
  }

  .mainPageHaveQuestions {
    margin-top: 150px;
  }

  .mainPageHeader {
    margin-top: 40px;
  }

  .mainPageReviews {
    margin-top: 150px;
  }
}
