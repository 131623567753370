.root {
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.root:last-child {
  margin-bottom: 0px;
}

.img {
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 3px;
}

.imgWrapper {
  width: 52px;
  height: 52px;
  border: 1px solid #0D8E74;
  border-radius: 6px;
}

.arrow {
  margin-top: 20px;
  margin-left: auto;
  cursor: pointer;
}