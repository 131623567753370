.AboutPage {
  margin-top: 200px;
}

.root {
  position: relative;
}

.banner1 {
  position: absolute;
  width: 100%;
  top: -130px;
  left: 1px;
  max-width: 100%;

  @media screen and (max-width: 1000px) {
    top: -65px;
  }

  @media screen and (max-width: 500px) {
    top: -30px;
  }
}

.banner2 {
  padding-top: 30%;
  max-width: 100%;
}

.banner3 {
  margin-top: 80px;
  max-width: 100%;
}

.banner4 {
  margin-top: 70px;
  max-width: 100%;
}