.avatar {
  background-color: #C3C3C3;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatarImg {
  width: 100%;
}
