@import '../../../../app/styles/globals/colors.scss';

.mobileBlockWithInformationAboutPlatform {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.text {
  z-index: 1;
}

.first, .second, .third, .fourth {
  position: relative;
  border-radius: 30px;
  padding: 20px 15px;

  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.first {
 background-color: $blueSoft;

  img {
    right: 30px;
    width: 69px;
  }
}

.second {
  background-color: $purpleSoft;
  
  img {
    width: 83px;
    right: -40px;
  }
}

.third {
  background-color: $orange;
  
  img {
    width: 77px;
    right: 15px;
  }
}

.fourth {
  background-color: $black;
  
  img {
    width: 110px;
    right: -10px;
  }
}
