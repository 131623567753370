.image {
  border-radius: 8px;
  width: 100px;
  height: 100px;
  background-color: rgb(190, 190, 190);
}

.courseCardWithMarketingWrapper {
  position: relative;
}

.courseCardWithMarketing {
  padding-top: 24px;
  padding-right: 24px;
  padding-left: 24px;
  border-radius: 12px;
  background-color: #FFFFFF;
  height: 100%;
  padding-bottom: calc(24px + 40px);
}

button.rating {
  position: absolute;
  left: 24px;
  bottom: 24px;
  height: 28px;
  background-color: #FAF9F9;
  border-radius: 15px;
  padding: 4px 12px;
}