.textarea {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
  
  background-color: white;
  border: 1px solid var(--Accent-Secondary, #C3C3C3);
  border-radius: 8px;
  width: 100%;
  height: 225px;
  padding: 12px;
}