.calendar {
  width: 450px;
  aspect-ratio: 1 / 1;
  padding: 16px;
  background-color: #FFFFFF;
  border-radius: 12px;
}

.dateWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 4px;
}

.date {
  height: 100%;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dateWithEvent {
  border-radius: 50%;
  background-color: #0D8E74;
}

.dateToday {
  border-radius: 50%;
  border: #0D8E74 solid 1px;
  background-color: #FFFFFF;
}

.points {
  position: absolute;
  right: 4px;
  top: 4px;
}

.bluePoent, .greenPoent, .orangePoent {
  border-radius: 50%;
  height: 6px;
  width: 6px;
}

.bluePoent, .blue {
  background-color: #0E99E8;
}

.greenPoent, .green {
  background-color: #00CC39;
}

.orangePoent, .orange {
  background-color: #FFAD30;
}

.time, .title {
  height: 64px;
  border-radius: 8px;
}

.time {
  width: 50px;
  padding: 0 8px;
}

.title {
  padding: 0 12px;
}

.blockTitle, .blockMonth {
  height: 68px;
  display: flex;
  align-items: center;
  padding-left: 16px;
}
