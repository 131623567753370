
.root {
  margin: 12px 0px 12px 0px;
  display: inline-flex;
}
.tab {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  border: none;
  background: transparent;
  color: #5B5B5B;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  border-bottom: 1px solid #5B5B5B;
  padding-bottom: 8px;
}
.active {
  color: #0D8E74;
 border-bottom: 2px solid #0D8E74;
}

