.detailedRating {
  padding: 16px;
  background-color: #FCFAFA;
  border-radius: 12px;
}

.countRatings {
  width: 46px;
  text-align: right;
}

.averageRating {
  width: 128px;
}
