.authorizationPage {
  min-height: 100dvh;
  display: flex;
  align-items: center;
  position: relative;
}

.backgroundAuthImg {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.hide1300 {
  @media screen and (max-width: 1300px) {
    display: none;
  }
}

.form {
  background-color: #FFFFFF;
  border-radius: 24px;
  box-shadow: 0px 14px 20px -4px rgba(81, 81, 91, 0.08);
}

.formCustom {
  background-color: transparent;
  border-radius: 0px;
  padding: 0px;
  box-shadow: none;
}

.line {
  border-color: rgba(228, 228, 228, 1);
  border-style: solid;
  border-width: 1px;
  width: 1px;
}

.w400 {
  min-width: 400px;
}

.p32 {
  padding: 32px;
}

.littleImage {
  height: 217px;
  margin: 0 auto;
}

.html {
  width: 464px;
  padding: 32px;
}

.mobileForm {
  background-color: #FFFFFF;
  margin: 16px;
  border-radius: 16px;
  padding: 16px;
}
