.root {
  background-color: #333;
  padding: 44px 32px;
  position: relative;
  background: url('../../../../../shared/assets/images/navigateLessonBg.png') center center/cover no-repeat;
}

.btns {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.btn {
  color: #fff;
  background-color: #0D8E74;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.lesson {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.bottom {
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  color:#E4E4E4;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
}

.subtitle {
  margin-top: 24px;
  color: #E4E4E4;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  column-gap: 7px;
}

.menuBtn {
  color: #0D8E74;
  font-weight: 500;
  font-size: 16px;
  line-height: 24;
  width: 170px;
  height: 60px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.active {
  transform: rotate(180deg);
}
