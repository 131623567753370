.input {
  height: 56px;
  border-width: 1px;
  border-style: solid;
  border-color: #CCCBD3;
  border-radius: 6px;
  padding: 0 16px;
  transition: border-color .3s ease;  

  &.focus {
    border-color: #34C759;
  }

  & input {
    border: none;
    height: 100%;
    outline: none;
    margin-left: 12px;
  }
}

.inputError {
  border-color: #FF6161;
}

.errorText {
  color: #FF6161;
  font-size: 12px;
  padding-left: 16px;
  padding-top: 6px;
}
