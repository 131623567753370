@import '../../../../app//styles//globals/variables.scss';

// general

.buttonSquareUI {
  border-radius: 500px;
  font-family: $font-family-primary;
  transition: background-color 0.3s ease;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stretch {
  width: 100%;
}

// Nodes

.leftNode {
  margin-right: $spacing-4;
}

.rightNode {
  margin-left: $spacing-4;
}

// size

.m {
  font-size: $font-size-14;
  line-height: $line-height-20;
  min-height: 50px;
  padding-top: $spacing-8;
  padding-bottom: $spacing-8;
  padding-left: $spacing-30;
  padding-right: $spacing-30;
}

.l {
  font-size: $font-size-14;
  line-height: $line-height-20;
  height: 100px;
  padding-left: $spacing-30;
  padding-right: $spacing-30;
}

// color

.outline.black {
  color: #0E0E06;
  border-color: #0E0E06;
  border-style: solid;
}

.filled.black {
  background-color: $black;
  color: #FFFFFF;

  &:disabled {
    background-color: $gray-2;
  }
}

.filled.white {
  background-color: #FFFFFF;
  color: #0E0E06;
}

.filled.dark {
  background-color: #0E0E06;
  color: #FFFFFF;
}

.filled.greenMain {
  background-color: $greenMain;
  color: $white;
}

.filled.blue {
  background-color: $blue;
  color: $white;
}

// variant

.filled {
  border: none;
}

.outline {
  background-color: transparent;
  border-width: 1px;
}

//

.disabled {
  opacity: 40%;
  pointer-events: none;
}