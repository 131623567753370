.time {
  background-color: #F2F2F2;
  padding: 2px 8px;
  border-radius: 12px;
  color: #0E0E06;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.root {
  padding: 8px 0px;
}

.wrapper {
  padding: 14px 0px;
  border-bottom: 1px solid #E4E4E4;
  display: flex;
  column-gap: 12px;
  cursor: pointer;
}

.wrapper:last-child {
  border-bottom: none;
}
.wrapper:hover {
  background-color: rgba(0, 204, 57, 0.08);

  & .time {
    background-color: rgba(0, 204, 57, 1);
    color: #fff;
  }
  & .text {
    color: rgba(13, 142, 116, 1);
  }
}

.text {
  color: #5B5B5B;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

