.backBtn {
  text-decoration: none;
  color: #9F9FA0;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.btn {
  color:  #5B5B5B;
  font-size: 20px;
  font-weight: 600px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid #5B5B5B;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}